.title-header-bill {
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 800;
}
.color-print {
    color: black;
    font-family: 'Times New Roman', Times, serif;
}
.txt-address {
    font-size: 1rem;
}
.title-name-bill {
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: 16px;
    line-height: normal;
}
.img-qr {
    width: 140px;
    height: 140px;
}
.strong-bill {
    font-size: 1rem;
}
.txt-body {
    font-size: 1.05rem;
}
.row-body {
    padding-left: 30px;
    padding-right: 30px;
}
.row-body .table-bill {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse !important;
}
.row-body .table-bill th {
    border: 1px solid black;
    text-align: center;
    
}
.row-body .table-bill td {
    border: 1px solid black;
    text-align: center;
    
}
.table-bill td {
    padding-top: 5px;
    padding-bottom: 5px;
}
.center-col {
    text-align: center;
}
.title-table {
    font-size: 1.2rem;
}
.content-td {
    font-size: 1.3rem;
    font-weight: 500;
}
.unit {
    font-weight: 700;
}
.img-logo-bill {
    width: 100px;
    height: 80px;
}
.txt-type {
    font-style: italic;
}