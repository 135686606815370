.title-divider {
    font-size: 1.3rem;
    font-weight: 600;
    color: #016ac6;
}
.date-picker {
    height: 40px;
    width: 100%;
}
.row-attach {
    border: 1px solid #d9d9d9;
    padding: 8px 8px;
    border-radius: 8px;
    margin-top: 4px;
}
.row-attach .ant-btn, .ant-radio-group .ant-radio-button-wrapper {
    line-height: 0px;
}