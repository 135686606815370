.title-header-bill {
    font-size: 1.1rem;
    line-height: normal;
    font-weight: 800;
}
.color-print {
    color: black;
    font-family: 'Times New Roman', Times, serif;
    line-height: normal;
}
.txt-address {
    font-size: 0.8rem;
}
.img-qr {
    width: 140px;
    height: 140px;
}
.strong-bill {
    font-size: 0.8rem;
}
.txt-body {
    font-size: 0.8rem;
    line-height: normal;
}
.row-body {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
}
.row-body .table-bill {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse !important;
}
#table-123 th {
    border: 1px solid black;
    text-align: center;
    
}
#table-123 td {
    border: 1px solid black;
    text-align: center;
    
}
.table-bill td {
    padding-top: 5px;
    padding-bottom: 5px;
}
.center-col {
    text-align: center;
}
.title-table {
    font-size: 0.8rem;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
}
.title-table-support {
    font-size: 0.8rem;
    font-weight: 400;
    padding-left: 2px;
    padding-right: 2px;
    width: 80px;
}
.content-td {
    font-size: 0.8rem;
    font-weight: 700;
}
.img-logo-bill {
    width: 100px;
    height: 80px;
}
.txt-title {
    font-size: 0.9rem;
}
.row-title {
    margin-top: 100;
}
.border-bottom {
    border-bottom: 1px darkblue dashed;
    text-align: center;
}